import React from "react";
import ReactDOMClient from "react-dom/client";
import {Frame, FrameRu} from "./screens/Frame";
import {Vladimir, VladimirRu} from "./screens/Schwarz";
import {Qr1, Qr2} from "./screens/Qr";
import {Route, Routes, BrowserRouter} from 'react-router-dom';

/*const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(<Frame />);*/

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render((
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Frame/>}/>
            <Route path="/appomart" element={<Frame/>}/>
            <Route path="/ru/appomart" element={<FrameRu/>}/>
            <Route path="/schwarzman" element={<Vladimir/>}/>
            <Route path="/ru/schwarzman" element={<VladimirRu/>}/>
            <Route path="/qr-1" element={<Qr1/>}/>
            <Route path="/qr-2" element={<Qr2/>}/>
        </Routes>
    </BrowserRouter>
));

