import React from "react";
import { Helmet } from "react-helmet";
import {TablerIconArrow} from "../../components/TablerIconArrow";
import {TablerIconSlideshow1} from "../../icons/TablerIconSlideshow1";
import "./style.css";
import {lang} from "./ru.js";

//export const Frame = (): JSX.Element => {
export const Frame = () => {
    return (
        <div className="frame">
            <Helmet>
                <title>{lang.title}</title>
                <link rel="shortcut icon" href="/img/favicon.ico"/>
            </Helmet>
            <div className="div">
                <img className="image" alt="Image" src="/img/vector.png"/>
                <img className="vector" alt="Vector" src="/img/vector.png"/>
                <img className="img" alt="Image" src="/img/image-97867.png"/>
                <img className="vector-2" alt="Vector" src="/img/vector.png"/>
                <div className="div-2">
                    <div className="frame-wrapper">
                        <img className="img-2" alt="Frame" src="/img/frame-5903.svg"/>
                    </div>
                    <div className="div-5">
                        <div className="text-wrapper">{lang.contactUs}</div>
                        <div className="div-6">
                            <a className="group-wrapper"
                               href={lang.telegram}>
                                <div className="group">
                                    <img className="img-3" alt="Brand telegram" src="/img/brand-telegram-1.svg"/>
                                    <div className="text-wrapper-4">Telegram</div>
                                </div>
                            </a>
                            <a className="div-wrapper"
                               href={"https://api.whatsapp.com/send?phone=381637736647&text=Hey%20Appomart!%20%F0%9F%91%8B"}>
                                <div className="group-2">
                                    <img className="img-3" alt="Brand whatsapp" src="/img/brand-whatsapp-1.svg"/>
                                    <div className="text-wrapper-4">WhatsApp</div>
                                </div>
                            </a>
                            <a className="button-3"
                               href={lang.tidycall}>
                                <div className="group-3">
                                    <img className="img-4" alt="Frame" src="/img/frame-6181.svg"/>
                                    <div className="text-wrapper-5">Book a call</div>
                                </div>
                            </a>
                            <a className="button"
                               href={"mailto:hello@appomart.com"}>
                                <img className="tabler-icon" alt="Mail" src="/img/mail-2.svg"/>
                                <div className="text-wrapper-2">
                                    hello@appomart.com
                                </div>
                            </a>
                            <p className="p text1">
                                {lang.text1}
                            </p>
                        </div>
                    </div>
                    <div className="div-3">
                        <div className="text-wrapper">{lang.learnMore}</div>
                        <div className="div-4">
                            <a className="button" href={"https://appomart.com"}>
                                <div className="text-wrapper-2">Appomart.com</div>
                                <TablerIconArrow className="tabler-icon-arrow-down-right"/>
                            </a>
                            <a className="button-2"
                               href={"https://appomart.com/data/docs/portfolio/Appomart-Wide-portfolio-EN.pdf"}>
                                <TablerIconSlideshow1 className="tabler-icon" color="#357ED2"/>
                                <div className="text-wrapper-3"> Our portfolio</div>
                            </a>
                            <a className="button-2"
                               href={"https://appomart.com/data/docs/presentation/Appomart-EN.pdf"}>
                                <TablerIconSlideshow1 className="tabler-icon" color="#357ED2"/>
                                <div className="text-wrapper-3">Presentation</div>
                            </a>
                        </div>
                    </div>
                </div>
                {/*<div className="div-7">
                    <div className="rectangle"/>
                    <div className="div-8">
                        <div className="div-9">
                            <div className="text-wrapper-6">IT Project Development Platform</div>
                            <div className="text-wrapper-7">Appocore</div>
                        </div>
                        <div className="div-10">
                            <a className="button-4"
                               href={"https://appocore.io"}>
                                <div className="text-wrapper-8">Appocore.io</div>
                                <TablerIconArrow className="tabler-icon-arrow-instance"/>
                            </a>
                            <a className="button-5"
                               href={"https://appomart.com/data/docs/Appocore-Presentation-EN.pdf"}>
                                <img className="tabler-icon" alt="Download" src="/img/download-1.svg"/>
                                <div className="text-wrapper-9">Presentation</div>
                            </a>
                        </div>
                    </div>
                </div>*/}
                <div className="div-11">
                    <div className="text-wrapper-10">{lang.ourBranches}</div>
                    <div className="div-12">
                        <div className="element">
                            <div className="div-13">
                                <img className="APPOMART-BELGRADE" alt="Appomart BELGRADE"
                                     src="/img/appomart-belgrade.svg"/>
                                <div className="div-14">
                                    <a className="text-wrapper-11"
                                       href={"tel: +381 (63) 773-66-47"}>
                                        +381 (63) 773-66-47
                                    </a>
                                    <div className="text-wrapper-12">Gavrila Principa 43</div>
                                </div>
                            </div>
                            <div className="div-15">
                                <img className="image-2" alt="Image" src="/img/image-97869.png"/>
                                <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-306-3.png"/>
                                <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-307-1.png"/>
                            </div>
                        </div>
                        <div className="element">
                            <div className="div-13">
                                <img className="APPOMART-SINGAPORE" alt="Appomart SINGAPORE"
                                     src="/img/appomart-singapore.svg"/>
                                <div className="div-14">
                                    <a className="text-wrapper-11" href={"tel:+381 (63) 773-66-47"}>
                                        +381 (63) 773-66-47
                                    </a>
                                    <div className="text-wrapper-13">160 Robinson Road #14-04</div>
                                </div>
                            </div>
                            <div className="div-15">
                                <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-306-2.png"/>
                            </div>
                        </div>
                        <div className="element">
                            <div className="div-13">
                                <img className="APPOMART-SAINT" alt="Appomart SAINT"
                                     src="/img/appomart-saint-petersberg.svg"/>
                                <div className="div-14">
                                    <a className="text-wrapper-11"
                                       href={"tel:+7 (911) 833-33-84"}>
                                        +7 (911) 833-33-84
                                    </a>
                                    <div className="text-wrapper-12">Kurlyandskaya, 48</div>
                                </div>
                            </div>
                            <div className="div-15">
                                <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-307.png"/>
                                <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-306-1.png"/>
                            </div>
                        </div>
                        <div className="element">
                            <div className="div-13">
                                <img className="APPOMART-TEL-AVIV" alt="Appomart TEL AVIV"
                                     src="/img/appomart-tel-aviv.svg"/>
                                <div className="div-14">
                                    <a className="text-wrapper-11"
                                       href={"tel:+972 (55) 295-08-54"}>
                                        +972 (55) 295-08-54
                                    </a>
                                    <div className="text-wrapper-12">4, Berkovich St.</div>
                                </div>
                            </div>
                            <div className="div-15">
                                <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-306.png"/>
                                <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-308.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};