import React from "react";
import { Helmet } from "react-helmet";
import "./style.css";
import {lang} from "./en.js";

export const Vladimir = (): JSX.Element => {
    return (
        <div className="vladimir">
            <Helmet>
                <title>{lang.title}</title>
                <link rel="shortcut icon" href="/img/favicon.ico"/>
            </Helmet>
            <div className="rectangle"/>
            <div className="ellipse"/>
            <div className="div1">
                <div className="top-image">
                    <div className="mask-group1" alt="Mask group">
                        <div className="image-background1">
                            <img className="ellipse-2-1" alt="Ellipse" src="/img/ellipse-46.png"/>
                        </div>
                        <div className="text-wrapper-7-1">Vladimir Chernyi</div>
                    </div>
                </div>
            </div>
            <div className="frame-1-1">
                <p className="welcome-to-my">{lang.textTop1}</p>
                <div className="frame-2">
                    <a className="button"
                       href={lang.tidycall}>
                        <img className="img2" alt="Frame" src="/img/frame-6182.svg"/>
                        <div className="text-wrapper">Book a call</div>
                    </a>
                    <a className="button-2-1"
                       href={lang.telegram}>
                        <img className="img-2" alt="Brand telegram" src="/img/brand-telegram-1.svg"/>
                        <div className="text-wrapper">Telegram</div>
                    </a>
                    <a className="button-3"
                       href={"https://api.whatsapp.com/send?phone=381637736647&text=Hey%20Appomart!%20%F0%9F%91%8B"}>
                        <img className="img-2" alt="Brand whatsapp" src="/img/brand-whatsapp-1.svg"/>
                        <div className="text-wrapper">WhatsApp</div>
                    </a>
                </div>
            </div>
            <div className="frame-3-1">
                <div className="text-wrapper-2-1">Contact Me</div>
                <a className="button-4"
                   href={"mailto:vladimir.chyorniy@ieee.org"}>
                    <img className="img" alt="Mail" src="/img/mail-2.svg"/>
                    <div className="text-wrapper-3-1">vladimir.chyorniy@ieee.org</div>
                </a>
            </div>
            <div className="frame-4-1">
                <a className="button-5" href={"tel:+381 (63) 773-66-47"}>
                    <img className="image" alt="Image" src="/img/image-97869.png"/>
                    <div className="text-wrapper-4">+381 (63) 773-66-47</div>
                </a>
                <a className="button-5" href={"tel:+7 (911) 833-33-84"}>
                    <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-307.png"/>
                    <div className="text-wrapper-4">+7 (911) 833-33-84</div>
                </a>
            </div>
            <div className="frame-5-1">
                <div className="frame-6">
                    <div className="text-wrapper-5-1">{lang.digiDest}</div>
                    <p className="p">
                        {lang.textDigiDest}
                    </p>
                </div>
                <div className="frame-7">
                    <a className="button-5" href={"https://schwarzman.dev"}>
                        <div className="text-wrapper-6" rel="noopener noreferrer" target="_blank">
                            Schwarzman.dev
                        </div>
                    </a>
                    <a className="button-6" href={"https://appomart.com"}>
                        <img className="img" alt="Tabler icon arrow" src="/img/tabler-icon-arrow-down-right.svg"/>
                        <div className="text-wrapper-6">Appomart.com</div>
                    </a>
                    <a className="button-6" href={"https://appocore.io"}>
                        <img className="img" alt="Tabler icon arrow" src="/img/tabler-icon-arrow-down-right.svg"/>
                        <div className="text-wrapper-6">Appocore.io</div>
                    </a>
                </div>
            </div>
            <img className="vector" alt="Vector" src="/img/vector-54.svg"/>
        </div>
    );
};
