export const lang = {
    title: "Appomart. Get in touch.",
    tidycall: "https://tidycal.com/appomart/30-en",
    telegram: "https://t.me/appomart_serbia",
    contactUs: "Contact us",
    learnMore: "Learn More",
    text1: "Send us a description of your idea or the technical specifications. Any materials you\n" +
        "provide\n" +
        "will\n" +
        "assist us in formulating a more precise proposal for your needs. Alternatively, feel\n" +
        "free to\n" +
        "reach out\n" +
        "to us through messengers or book a call directly.",
    ourBranches: "Our Branches"
}