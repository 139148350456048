export const lang = {
    title: "Schwarzman - страница контакты.",
    tidycall: "https://tidycal.com/appomart/30",
    telegram: "https://t.me/vovaschwarz",
    textTop1: "👋 Добро пожаловать! Я Владимир Черный, " +
        "основатель компании Appomart. Согласуйте звонок с IT экспертом, " +
        "обладающим обширным опытом в разнообразных проектах. " +
        "Всего пара кликов и мы готовы обсуждать идеи для вашего бизнеса. " +
        "Будем рады продуктивному диалогу! 👍",
    digiDest: "Цифровые направления",
    textDigiDest: "Изучите инновации, креативность и технологии, " +
        "лежащие в основе Appomart, моего личного сайта и Appocore»."
}