/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const TablerIconSlideshow1 = ({ color = "#357ED2", className }: Props): JSX.Element => {
  return (
    <svg
      className={`tabler-icon-slideshow-1 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.25 4.5H11.2575"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M13.5 2.25H4.5C3.25736 2.25 2.25 3.25736 2.25 4.5V10.5C2.25 11.7426 3.25736 12.75 4.5 12.75H13.5C14.7426 12.75 15.75 11.7426 15.75 10.5V4.5C15.75 3.25736 14.7426 2.25 13.5 2.25Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M2.25 9.75L5.25 6.75C5.59204 6.42087 5.98004 6.24759 6.375 6.24759C6.76996 6.24759 7.15796 6.42087 7.5 6.75L10.5 9.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M9.75 9L11.25 7.5C11.592 7.17087 11.98 6.99759 12.375 6.99759C12.77 6.99759 13.158 7.17087 13.5 7.5L15.75 9.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M6 15.75H6.0075"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M9 15.75H9.0075"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M12 15.75H12.0075"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

TablerIconSlideshow1.propTypes = {
  color: PropTypes.string,
};
