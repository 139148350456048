export const lang = {
    title: "Schwarzman - contact page.",
    tidycall: "https://tidycal.com/appomart/30-en",
    telegram: "https://t.me/vovaschwarz",
    textTop1: "👋 Welcome to my booking page! I'm Vladimir Chernyi, " +
        "founder of Appomart. Schedule a call with an IT expert " +
        "possessing extensive experience in diverse projects. " +
        "Just a few clicks, and we're ready to discuss insights " +
        "valuable for your business. Looking forward to a productive conversation! 👍",
    digiDest: "Digital Destinations",
    textDigiDest: "Explore the innovation, creativity, and technology behind Appomart, my personal site, and Appocore."
}