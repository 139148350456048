import React from "react";
import {Helmet} from "react-helmet";
import "./style.css";


//export const Frame = (): JSX.Element => {
export const Qr = (): JSX.Element => {
    return (
        <div className="frame">
            <Helmet>
                <title>Appomart</title>
                <link rel="shortcut icon" href="/img/favicon.ico"/>
                <meta http-equiv="Refresh" content="2; url='https://i.appomart.com/ru/appomart/'"/>
            </Helmet>
            <div className="center">
                <img src="/img/logo-wide.png" alt="Appomart"/>
                <div className="loading-container">
                    <div className="loading">
                        <div className="loading-center">
                            <div className="dot-container">
                                <span className="dot"></span>
                                <div className="dots">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};