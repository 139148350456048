export const lang = {
    title: "Appomart. Страница контакты.",
    tidycall: "https://tidycal.com/appomart/30",
    telegram: "https://t.me/appomart",
    contactUs: "Свяжитесь с нами",
    learnMore: "Узнать Больше",
    text1: "Отправьте нам описание вашей идеи или тех задание. " +
        "Любые материалы помогут сформулировать для вас более точное предложение. " +
        "И не стесняйтесь связаться с нами через мессенджеры или напрямую забронировать звонок.",
    ourBranches: "Наши локации"
}